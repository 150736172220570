<template>
  <section class="conOfNewsLetter h-100">
    <v-container>
      <v-row class="mt-3">
        <v-col cols="12">
          <Breadcrumb :BreadcrumbList="BreadcrumbList" />
          <HeaderPage :headerPage="{ part1: 'Clinical', part2: 'News' }" />
        </v-col>
      </v-row>
      <!-- <form class="conFormSubmit">
        <v-row>
          <v-col md="3" sm="6" cols="12">
            <div class="conDefaultInput">
              <v-text-field
                solo
                v-model="search.terms"
                label="Title Or Description"
                hide-details="auto"
              ></v-text-field>
            </div>
          </v-col>
          <v-col md="3" sm="6" cols="12">
            <div class="conSelectOption">
              <v-select
                outlined
                v-model="search.orderBy"
                :items="items"
                item-text="name"
                item-value="value"
                label="Order By"
                hide-details="auto"
                :menu-props="{ bottom: true, offsetY: true }"
                attach
              ></v-select>
            </div>
          </v-col>

          <v-col md="3" sm="6" cols="12">
            <div class="confBtns">
              <v-btn
                class="srearchBnt primaryBtn mr-4"
                @click="onSearch"
                :disabled="isLoadingSearch"
                :loading="isLoadingSearch"
              >
                Search
              </v-btn>
              <v-btn class="clearBtn secondary-outline-btn" @click="onClear">
                clear
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </form> -->
    </v-container>

    <section class="mt-3" v-if="listItems && listItems.length > 0">
      <v-container>
        <v-row>
          <!-- <v-col md="8" cols="12" v-if="isFiltered">
            <div class="conofcard">
              <router-link
                :to="{
                  name: 'newsLetterItem',
                  params: { id: listItems[0].id },
                }"
              >
                <v-card class="mx-auto" tile elevation="0">
                  <div class="conOfCardImg">
                    <v-img
                      height="380"
                      :src="listItems[0].image"
                      alt="item of the Image"
                      class="imgConCard"
                    >
                      <div class="conOfContent">
                        <div class="contentSec largItem">
                          <v-card-title class="cardTitle">
                            <div class="titleSec">
                              {{ listItems[0].title }}
                            </div>
                          </v-card-title>
                          <v-card-text>
                            <div class="cardDesc" v-html="listItems[0].description">
                            </div>
                          </v-card-text>
                        </div>
                      </div>
                    </v-img>
                  </div>
                </v-card>
              </router-link>
            </div>
          </v-col> -->
          <v-col md="6" cols="12">
            <div class="conofcard mr-3">
              <a href="https://cn.saudiblood.org/">
                <v-card class="mx-auto" tile elevation="0">
                  <div class="conOfCardImg">
                    <img
                      src="@/assets/img/old-m.png"
                      alt="item of the Image"
                      class="imgConCard"
                    />
                    <div class="conOfContent">
                      <div class="contentSec largItem">
                        <v-card-title class="cardTitle">
                          <div class="titleSec">First issue</div>
                        </v-card-title>
                      </div>
                    </div>
                    <!-- </img> -->
                  </div>
                </v-card>
              </a>
            </div>
          </v-col>
          <!--          <v-col-->
          <!--            md="4"-->
          <!--            sm="6"-->
          <!--            cols="12"-->
          <!--            v-for="(item, index) in listItems"-->
          <!--            :key="index"-->
          <!--          >-->
          <!--            <div class="conofcard">-->
          <!--              <router-link-->
          <!--                :to="{ name: 'newsLetterItem', params: { id: item.id } }"-->
          <!--              >-->
          <!--                <v-card class="mx-auto" tile elevation="0">-->
          <!--                  <div class="conOfCardImg">-->
          <!--                    <v-img-->
          <!--                      height="380"-->
          <!--                      :src="item.image"-->
          <!--                      alt="item of the Image"-->
          <!--                      class="imgConCard"-->
          <!--                    >-->
          <!--                      <div class="conOfContent">-->
          <!--                        <div class="contentSec">-->
          <!--                          <v-card-title class="cardTitle">-->
          <!--                            <div class="titleSec">-->
          <!--                              {{ item.title }}-->
          <!--                            </div>-->
          <!--                          </v-card-title>-->
          <!--                          <v-card-text>-->
          <!--                            <div class="cardDesc" v-html="item.description">-->
          <!--                              &lt;!&ndash; {{ item.description }} &ndash;&gt;-->
          <!--                            </div>-->
          <!--                          </v-card-text>-->
          <!--                        </div>-->
          <!--                      </div>-->
          <!--                    </v-img>-->
          <!--                  </div>-->
          <!--                </v-card>-->
          <!--              </router-link>-->
          <!--            </div>-->
          <!--          </v-col>-->
          <v-col md="6" cols="12">
            <div class="conofcard ml-3">
              <a
                href="https://e-magazine.360-simulation.com/Saudi-Society-for-Blood-Disorders-The-second-issue-v3/#page/1"
              >
                <v-card class="mx-auto" tile elevation="0">
                  <div class="conOfCardImg">
                    <img
                      src="@/assets/img/new-m.png"
                      alt="item of the Image"
                      class="imgConCard"
                    />
                    <div class="conOfContent">
                      <div class="contentSec largItem">
                        <v-card-title class="cardTitle">
                          <div class="titleSec">2nd issue</div>
                        </v-card-title>
                      </div>
                    </div>
                    <!-- </img> -->
                  </div>
                </v-card>
              </a>
            </div>
          </v-col>
        </v-row>

        <v-row v-if="pagination.total > pagination.per_page">
          <v-col cols="12">
            <div class="text-center">
              <v-pagination
                v-model="pagination.current_page"
                :length="pagination.last_page"
                @input="goToPage(pagination.current_page)"
              ></v-pagination>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <div class="loaderContainer" v-else-if="isLoading">
      <v-progress-circular
        :size="120"
        :width="4"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <EmptyState v-else></EmptyState>
    <section class="stayWithUs mt-5">
      <v-container>
        <v-row class="align-center">
          <v-col md="6" cols="12">
            <div class="fHeader"><span>Stay updated with SSBD</span></div>
            <div class="desc">
              Excepteur sint occaecat cupidatat non proident
            </div>
            <div>
              <div class="conSubscribe d-flex mt-4">
                <v-text-field
                  class="subScribeInput"
                  solo
                  hide-details
                  elevation-0
                  placeholder="Enter Email Address"
                  aria-label="Search"
                />
                <v-btn class="subscibeBtn"> subscribe </v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </section>
</template>

<script>
import EmptyState from "@/modules/shared/components/emptystate";
import HeaderPage from "@/modules/shared/components/header-pages";
import Breadcrumb from "@/modules/shared/components/breadcrumbs-pages";
import { ApiService } from "@/modules/shared/services/api";
let apiServices = new ApiService();
export default {
  data: () => ({
    dialog: false,
    name: "",
    menu: false,
    items: [
      {
        name: "newest",
        value: 1,
      },
      {
        name: "oldest",
        value: 2,
      },
    ],
    search: {
      terms: "",
      orderBy: null,
    },
    updatedFilter: null,
    pagination: {
      current_page: 1,
    },
    BreadcrumbList: [
      {
        text: "Resources",
        disabled: true,
      },
      {
        text: "Clinical News",
        disabled: false,
      },
    ],
    listItems: null,
    isLoading: false,
    isLoadingSearch: false,
    isFiltered: true,
    updatedCurrent_page: null,
  }),
  components: {
    EmptyState,
    HeaderPage,
    Breadcrumb,
  },
  methods: {
    onClear() {
      // this.pagination.current_page = 1;
      this.search.terms = "";
      this.search.orderBy = null;
      this.pathByOaramSearch();
    },
    inzializeForm() {
      this.pagination.current_page = this.$route.query.current_page || 1;
      this.search.terms = this.$route.query.terms || "";
      this.search.orderBy = parseInt(this.$route.query.orderBy) || null;
    },
    pathByOaramSearch() {
      const query = Object.entries(this.search).reduce((acc, [key, val]) => {
        if (!val) return acc;
        return { ...acc, [key]: val };
      }, {});
      if (
        JSON.stringify(this.updatedFilter) != JSON.stringify(query) ||
        this.updatedCurrent_page != this.pagination.current_page
      ) {
        this.$router.replace({
          name: "newsLetter",
          query: { current_page: this.pagination.current_page, ...query },
        });
        this.updatedFilter = query;
        this.updatedCurrent_page = this.pagination.current_page;
      }
    },
    onSearch() {
      this.pagination.current_page = 1;
      this.pathByOaramSearch();
    },
    goToPage() {
      this.pathByOaramSearch();
      this.getListData();
      window.scrollTo(0, 0);
    },
    getListData() {
      this.listItems = [];
      this.isLoading = true;
      this.isLoadingSearch = true;

      apiServices
        .post("newsletters?page=" + this.pagination.current_page, this.search)
        .then((res) => {
          if (res) {
            this.listItems = res.data;
            // this.sliceFirstItem();
            // this.filterItem = this.listItems;
            this.pagination = res.meta;
            this.isLoading = false;
            this.isLoadingSearch = false;
          } else {
            this.isLoading = false;
            this.isLoadingSearch = false;
          }
        });
    },
    // checkIsFiltered() {
    //   if (this.search.terms || this.search.orderBy) this.isFiltered = false;
    //   else this.isFiltered = true;
    // },
    // sliceFirstItem() {
    //   if (this.isFiltered) {
    //     this.filterItem = this.listItems.splice(1);
    //   } else {
    //     this.filterItem = this.listItems;
    //   }
    // },
  },
  watch: {
    "$route.params.query": {
      handler: function () {
        this.inzializeForm();
        // this.checkIsFiltered();
        this.getListData();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style lang="scss">
@import "./_news-letter.scss";
</style>
